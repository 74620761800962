<template>
  <div class="box">
    <el-card shadow="never">
      <div class="ju-end flex">
        <el-button type="primary" @click="saveCard">保存</el-button>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="名片风格" name="0">
          <el-form class="mt-16" label-width="120px">
            <el-form-item label="选择风格">
              <div class="flex flex-wrap">
                <div class="style-item" :class="ruleForm.style == i.id ? 'active' : ''" v-for="(i, n) in styleList" :key="n" @click="changeStyle(i.type)">
                  <img v-if="i.type == ruleForm.cardTemplate" :src="i.surl" alt="" />
                  <img v-else :src="i.url" alt="" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="选择名片">
              <div class="flex flex-wrap">
                <div class="card-item" :class="ruleForm.style == i.id ? 'active' : ''" v-for="(i, n) in cardList" :key="n" @click="changeCard(i.id)">
                  <img :src="i.url" alt="" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="多职位" v-if="isBoss && ruleForm.style == 13">
              <el-input v-model="ruleForm.morePosition" placeholder="多个职位请用逗号隔开"></el-input>
            </el-form-item>
            <el-form-item label="企业LOGO" v-if="ruleForm.style == 13">
              <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg" :class="{ disabled: uploadDisabled1 }" :limit="1" :on-progress="handProgress" :on-success="handleSuccessCompanyLogo" :on-remove="handleRemoveCompanyLogo" :file-list="fileList1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="h100p d-flex align-items-center">
                <p>建议上传750x600，格式可为png、jpg或jpeg</p>
              </div>
            </el-form-item>
            <el-form-item label="背景图" v-if="
                ruleForm.style == 1 ||
                ruleForm.style == 2 ||
                ruleForm.style == 5 ||
                ruleForm.style == 6 ||
                ruleForm.style == 11 ||
                ruleForm.style == 12 ||
                ruleForm.style == 13
              ">
              <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg" :class="{ disabled: uploadDisabled2 }" :limit="1" :on-progress="handProgress" :on-success="handleSuccessHdIcon" :on-remove="handleRemoveHdIcon" :file-list="fileList2">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="h100p d-flex align-items-center">
                <p>建议上传600x600，格式可为png、jpg或jpeg</p>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="个人信息" name="1">
          <el-form class="mt-16" label-width="120px">
            <el-form-item label="头像">
              <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg" :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress" :on-success="handleSuccessLogo" :on-remove="handleRemoveLogo" :file-list="fileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="h100p d-flex align-items-center">
                <p>建议上传600x600，格式可为png、jpg或jpeg</p>
              </div>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input disabled v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="微信号">
              <el-input v-model="ruleForm.personalWx" placeholder="请输入微信号"></el-input>
            </el-form-item>
            <el-form-item label="个人简介">
              <el-input type="textarea" :rows="5" v-model="ruleForm.briefIntroduction" placeholder="请输入个人简介"></el-input>
            </el-form-item>
            <el-form-item label="我的图片">
              <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg" :class="{ disabled: uploadDisabled3 }" :limit="9" :on-progress="handProgress" :on-success="handleSuccessImg" :on-remove="handleRemoveImg" :file-list="fileList3">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="h100p d-flex align-items-center">
                <p>建议上传600x600，格式可为png、jpg或jpeg</p>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="企业信息" name="2">
          <el-form class="mt-16" label-width="120px">
            <el-form-item label="企业名称">
              <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="企业简称">
              <el-input v-model="ruleForm.companyShortName" placeholder="请输入企业简称"></el-input>
            </el-form-item>
            <el-form-item label="部门">
              <el-input v-model="ruleForm.cdepartment" placeholder="请输入部门名称"></el-input>
            </el-form-item>
            <el-form-item label="职位">
              <el-input v-model="ruleForm.position" placeholder="请输入职位名称"></el-input>
            </el-form-item>
            <el-form-item label="公司网址">
              <el-input v-model="ruleForm.officialWebsite" placeholder="请输入公司网址"></el-input>
            </el-form-item>
            <el-form-item label="公司地址">
              <el-input v-model="ruleForm.address" disabled placeholder="如：广东省深圳市龙岗区中兴路19号布吉侨联大厦3层303"></el-input>
              <div class="flex mt-6 w100p">
                <div class="w100p">
                  <el-input id="suggestId" v-model="address" placeholder="在此修改地址"></el-input>
                  <div id="baidumap"></div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="企业介绍">
              <quill-editor style="height: 100%" v-model="ruleForm.companyDesc" ref="myQuillEditor" :options="editorOption" />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="功能展示" name="3">
          <el-form class="mt-16" label-width="120px">
            <el-form-item label="一键加微">
              <el-switch v-model="ruleForm.addWx"> </el-switch>
            </el-form-item>
            <el-form-item label="企业微信">
              <el-switch v-model="ruleForm.isEnterpriseWeChat"> </el-switch>
            </el-form-item>
            <el-form-item label="个人简介">
              <el-switch v-model="ruleForm.isIntro"> </el-switch>
            </el-form-item>
            <el-form-item label="我的图片">
              <el-switch v-model="ruleForm.isOpenPhoto"> </el-switch>
            </el-form-item>
            <el-form-item label="企业视频">
              <el-switch v-model="ruleForm.infoList"> </el-switch>
            </el-form-item>
            <el-form-item label="公司介绍">
              <el-switch v-model="ruleForm.isOpenIntroduce"> </el-switch>
            </el-form-item>
            <el-form-item label="官网卡片">
              <el-switch v-model="ruleForm.isOpenOfficialCard"> </el-switch>
            </el-form-item>
            <el-form-item label="更多功能">
              <el-switch v-model="ruleForm.isShowFunction"> </el-switch>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
  
  <script>
import { getUserCard, updateCard } from "@/api/editCard.js";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请输入";
quillConfig.modules.imageDrop = true;
let ac;
export default {
  data() {
    return {
      editorOption: quillConfig,
      isBoss: false,
      ruleForm: {},
      address: "",
      styleList: [
        {
          name: "极简风格",
          url: "http://cdn-oss.jquen.com/crm-card/card-style/template1.png",
          surl: "http://cdn-oss.jquen.com/crm-card/card-style/template1_select.png",
          type: "SIMPLE",
        },
        {
          name: "简约风格",
          url: "http://cdn-oss.jquen.com/crm-card/card-style/template2.png",
          surl: "http://cdn-oss.jquen.com/crm-card/card-style/template2_select.png",
          type: "DEFAULT",
        },
        {
          name: "商务风格",
          url: "http://cdn-oss.jquen.com/crm-card/card-style/template3.png",
          surl: "http://cdn-oss.jquen.com/crm-card/card-style/template3_select.png",
          type: "LUXURY",
        },
        {
          name: "时尚风格",
          url: "http://cdn-oss.jquen.com/crm-card/card-style/template4.png",
          surl: "http://cdn-oss.jquen.com/crm-card/card-style/template4_select.png",
          type: "PUBLICITY",
        },
        {
          name: "门店风格",
          url: "http://cdn-oss.jquen.com/crm-card/card-style/template5.png",
          surl: "http://cdn-oss.jquen.com/crm-card/card-style/template5_select.png",
          type: "STYLE1",
        },
      ],
      cardList: [
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style0.png",
          id: 0,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style1.png",
          id: 1,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style2.png",
          id: 2,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style3.png",
          id: 3,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style4.png",
          id: 4,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style5.png",
          id: 5,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style6.png",
          id: 6,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style7.png",
          id: 7,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style8.png",
          id: 8,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style9.png",
          id: 9,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style10.png",
          id: 10,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style11.png",
          id: 11,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style12.png",
          id: 12,
        },
        {
          url: "https://cdn-oss.jquen.com/crm-card/card-style/card_style13.png",
          id: 13,
        },
        {
          url: 'https://cdn-oss.jquen.com/crm-card/card-style/card_style15.png',
          id: 14
        },
        {
          url: 'https://cdn-oss.jquen.com/crm-card/card-style/card_style16.png',
          id: 15
        },
      ],
      activeName: "0",
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length > 0;
    },
    uploadDisabled1() {
      return this.fileList1.length > 0;
    },
    uploadDisabled2() {
      return this.fileList2.length > 0;
    },
    uploadDisabled3() {
      return this.fileList2.length > 9;
    },
  },
  created() {
    this.isBoss = localStorage.getItem("isBoss");
    console.log(this.isBoss, "是否boss");
    this.getUserCard();
  },
  methods: {
    changeCard(id) {
      this.ruleForm.style = id;
    },
    changeStyle(type) {
      this.ruleForm.cardTemplate = type;
    },
    getUserCard() {
      getUserCard({ ignore: true }).then((res) => {
        console.log(res, "获取名片信息");
        this.ruleForm = res.data;
        if (this.ruleForm.cdepartment == "部门未分配") {
          this.ruleForm.cdepartment = "";
        }
        if (this.ruleForm.logo) {
          this.fileList = [
            {
              name: "",
              url: this.ruleForm.logo,
            },
          ];
        }
        if (this.ruleForm.comlogo) {
          this.fileList1 = [
            {
              name: "",
              url: this.ruleForm.comlogo,
            },
          ];
        }
        if (this.ruleForm.hdIcon) {
          this.fileList2 = [
            {
              name: "",
              url: this.ruleForm.hdIcon,
            },
          ];
        }
        if (this.ruleForm.photo) {
          let arr = this.ruleForm.photo.split(",");
          arr.forEach((el) => {
            if (el !== "") {
              this.fileList3.push({
                name: "",
                url: el,
              });
            }
          });
        }
      });
    },
    saveCard() {
      this.ruleForm.address = this.address;
      if (this.fileList3 && this.fileList3.length > 0) {
        let arr = [];
        this.fileList3.forEach((el) => {
          arr.push(el.url);
        });
        this.ruleForm.photo = arr.join(",");
      }
      if (!this.ruleForm.cdepartment) {
        this.ruleForm.cdepartment = "部门未分配";
      }
      console.log(this.ruleForm, "保存名片数据");
      updateCard(this.ruleForm).then((res) => {
        this.$message.success("保存成功");
        this.$router.go(-1);
      });
    },
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
      if (name == 2) {
        let map = new BMap.Map("baidumap");
        ac = new BMap.Autocomplete({
          //建立一个自动完成的对象
          input: "suggestId",
          location: map,
        });
        ac.addEventListener("onconfirm", this.setValue);
      }
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    // 用户头像
    handleRemoveLogo(file, fileList) {
      this.fileList = [];
      this.ruleForm.logo = "";
    },
    handleSuccessLogo(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList = fileList;
      this.ruleForm.logo = `https://cdn-oss.jquen.com/${response.data}`;
    },
    // 企业logo--名片
    handleRemoveCompanyLogo(file, fileList) {
      this.fileList1 = [];
      this.ruleForm.comlogo = "";
    },
    handleSuccessCompanyLogo(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList1 = fileList;
      this.ruleForm.comlogo = `https://cdn-oss.jquen.com/${response.data}`;
    },
    // 背景图--名片
    handleRemoveHdIcon(file, fileList) {
      this.fileList2 = [];
      this.ruleForm.hdIcon = "";
    },
    handleSuccessHdIcon(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList2 = fileList;
      this.ruleForm.hdIcon = `https://cdn-oss.jquen.com/${response.data}`;
    },
    // 我的图片
    handleRemoveImg(file, fileList) {
      this.fileList3 = fileList;
    },
    handleSuccessImg(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList3.push({
        url: `https://cdn-oss.jquen.com/${response.data}`,
        name: "",
      });
      console.log(this.fileList3, "我的体图片");
    },
  },
};
</script>
  
<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
.card-item {
  width: 124px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 2px solid #ffffff;
  transition: all 0.3s;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.card-item.active {
  border: 2px solid #3258a5;
  transition: all 0.3s;
}

.style-item {
  width: 100px;
  height: 160px;
  flex-shrink: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>